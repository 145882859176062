import React from 'react';

import { useHistory } from 'react-router';

import { useForm } from 'react-hook-form';

import { useMutation } from '@apollo/react-hooks';

import { MUTATION_CAMPAIGN_ADD, MUTATION_CAMPAIGN_UPDATE } from '@taboset/graphql';

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'


const useStyles = makeStyles((theme) => ({
  verticalForm: {
    '& > *': {
      marginTop: theme.spacing(1),
    },
  },
}));

/*eslint-disable */
export interface Campaign {
  id: string,
  name: string,
  max_number_of_players: number
  owner_uuid: string
}
/*eslint-enable */

export interface CampaignsFormProps {
  campaign?: Campaign
}

export const CampaignsForm = (props: CampaignsFormProps) => { // eslint-disable-line
  const history = useHistory();
  const { verticalForm } = useStyles()

  const { register, handleSubmit, errors } = useForm({
    defaultValues: props.campaign ? props.campaign : {}
  });

  const [addCampaign, { loading: addLoading, data: campaignAddData }] = useMutation(MUTATION_CAMPAIGN_ADD, { // eslint-disable-line
    onCompleted({ insert_campaign: { affected_rows: inserted, returning: [campaign] } }) {
      console.log("We added a campaign!", inserted, campaign, campaign.id);
      history.push(`/campaigns/`);
    }
  });

  const [updateCampaign, { loading: updateLoading, data: campaignUpdateData }] = useMutation(MUTATION_CAMPAIGN_UPDATE, { // eslint-disable-line
    onCompleted({ update_campaign: { affected_rows: updated } }) {
      console.log("We updated a campaign!", updated);
      history.push(`/campaigns/`);
    }
  });

/*eslint-disable */
  const onSubmit = (addData: any) => { // eslint-disable-line
    if (props.campaign) {
      updateCampaign({
        variables: {
          id: props.campaign.id,
          name: addData.name,
          max_number_of_players: Number(addData.max_number_of_players)
        }
      });
    } else {
      addCampaign({
        variables: {
          campaign: {
            name: addData.name,
            max_number_of_players: addData.max_number_of_players,
          }
        }
      });
    }
  };
  /*eslint-enable */
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={verticalForm}>
      <Grid item>
        <TextField
          inputRef={register({ required: true })}
          name="name"
          id="name"
          label="Campaign Name"
          error={errors.name !== undefined}
          helperText={errors.name !== undefined && "Please enter a name for your campaign"}
        />
      </Grid>
      <Grid item>
        <TextField
          inputRef={register({ required: true, pattern: /\d+/ })}
          name="max_number_of_players"
          id="max_number_of_players"
          label="Max Players"
          type="number"
          defaultValue={1}
          inputProps={{ min: 1 }}
          error={errors.max_number_of_players !== undefined}
          helperText={errors.max_number_of_players !== undefined  && "Please enter the player cap for your campaign"}
        />
      </Grid>
      <Button variant="contained" color="primary" type="submit">Save Campaign</Button>
    </form>
  )
}

export default CampaignsForm;
