import React, { useState } from 'react';

import styled from 'styled-components';

import { useLazyQuery } from '@apollo/react-hooks';

import { useParams, withRouter } from 'react-router-dom';

import { CAMPAIGN_QUERY_BY_ID } from '@taboset/graphql';

import CampaignsForm from '../campaigns-form/campaigns-form';

const StyledCampaignsAdd = styled.div`

`;

function getCampaignData(data: any) { // eslint-disable-line
  return (data.campaign[0]);
}

export const CampaignsAdd = () => {
  const { campaignId } = useParams();

  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState(null);

  const updateCampaign = (campaignData) => {
    setCampaign(getCampaignData(campaignData));
    setLoading(false);
  };

  const [
    fetchCampaign,
    { called, data, loading: queryLoading, error }, // eslint-disable-line
  ] = useLazyQuery(CAMPAIGN_QUERY_BY_ID, {
    variables: {
      id: campaignId,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (d) => updateCampaign(d)
  });

  if (campaignId && !campaign && !loading) {
    setLoading(true);
    fetchCampaign();
  }

  if (loading) return (<h1>Loading</h1>);

  return (
    <StyledCampaignsAdd>
      <h1>Add a new Campaign</h1>
      <CampaignsForm campaign={campaign} />
    </StyledCampaignsAdd>
  );
};

export default withRouter(CampaignsAdd);
