import gql from 'graphql-tag';

export const MUTATION_CAMPAIGN_ADD = gql`
  mutation ($campaign: [campaign_insert_input!]!) {
    insert_campaign(objects: $campaign) {
      affected_rows
      returning {
        id
        name
      }
    }
  }
`;

export const MUTATION_CAMPAIGN_UPDATE = gql`
  mutation ($id: uuid!, $name: String!, $max_number_of_players: Int!) {
    update_campaign(
      where: { id: { _eq: $id } }
      _set: {
        name: $name,
        max_number_of_players: $max_number_of_players
      }
    ) {
      affected_rows
    }
  }
`;


export const MUTATION_CAMPAIGN_DELETE = gql`
  mutation ($id: uuid!) {
    delete_campaign(
      where: {
        id: {
          _eq: $id
        }
      }
    ) {
      affected_rows
    }
  }
`;
