import React from 'react';

import { BrowserRouter, Switch, Route, withRouter } from 'react-router-dom';

import CharactersList from './characters-list/characters-list';
import CharactersAdd from './characters-add/characters-add';
import CharactersView from './characters-view/characters-view';

export const Characters = ({ match }) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={match.url + '/'} component={CharactersList} />
        <Route path={match.url + '/add/'} component={CharactersAdd} />
        <Route exact path={match.url + '/:characterId/'} component={CharactersView} />
        <Route path={match.url + '/:characterId/edit'} component={CharactersAdd} />
      </Switch>
    </BrowserRouter>
  );
};

export default withRouter(Characters);
