import React from 'react';

import { Switch, Route, withRouter } from 'react-router-dom';

import CampaignsList from './campaigns-list/campaigns-list';
import CampaignsAdd from './campaigns-add/campaigns-add';
import CampaignsView from './campaigns-view/campaigns-view';

export const Campaigns = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.url + '/'} component={CampaignsList} />
      <Route path={match.url + '/add/'} component={CampaignsAdd} />
      <Route exact path={match.url + '/:campaignId/'} component={CampaignsView} />
      <Route path={match.url + '/:campaignId/edit'} component={CampaignsAdd} />
    </Switch>
  );
};

export default withRouter(Campaigns);
