import React from 'react';

import styled from 'styled-components';
import PageHeaderAction from './page-header-action';

const PageHeaderCont = styled.div`
  display: flex;
  flex-direction: row;

  margin-bottom: 1rem;
`;

const PageHeaderH1 = styled.h1`
  font-size: 1.5em;
  font-weight: 400;
  flex-grow: 1;

  margin-top: 0em;
`;

const PageHeaderActions = styled.div`
  margin-left: 2rem;
  display: flex;
  flex-direction: row;
`;

const getAction = (action) => {
  return <PageHeaderAction key={action.title} {...action} />;
};

const getPageHeaderActions = (actions) => {
  if (!actions || !Array.isArray(actions) || actions.length === 0) return null;

  return (
    <PageHeaderActions>
      {actions.map((action) => {
        return getAction(action);
      })}
    </PageHeaderActions>
  );
};

export const PageHeader = (props) => {
  return (
    <PageHeaderCont>
      <PageHeaderH1>{props.children}</PageHeaderH1>
      {getPageHeaderActions(props.actions)}
    </PageHeaderCont>
  );
};

export default PageHeader;
