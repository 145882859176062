import React from 'react';

import Button from '@material-ui/core/Button';


export const SubmitButton = (props: any) => {
  return (
    <Button {...props}>
      {props.children}
    </Button>
  );
};

export default SubmitButton;
