import React, { useEffect, useCallback } from 'react';

import styled from 'styled-components';

import { useForm, ErrorMessage } from 'react-hook-form';

import { RouteComponentProps, Redirect } from 'react-router-dom';

import { TextField, Grid, Button } from '@material-ui/core';

import PageHeader from '../../../components/common/page-header/page-header';

import { AuthContext } from '../../../contexts/auth';

const RegisterCont = styled.div``;

interface SelectUsernameState {
  source: string;
  token: string;
}

type SelectUsernameProps = RouteComponentProps<{}, {}, SelectUsernameState>;

export const SelectUsernamePage: React.FC<SelectUsernameProps> = ({ location, history }) => {
  const { authState, authDispatch } = React.useContext(AuthContext);
  const initialState = {
    username: '',
    password: '',
    isSubmitting: false,
    errorMessage: null,
  };

  const [formState, setFormState] = React.useState(initialState);

  const { register, handleSubmit, errors } = useForm();

  const validSources = ['google', 'facebok', 'steam', 'twitch'];

  const invalidState = useCallback(() => {
    if (!location.state) return true;

    if (!('source' in location.state) || !validSources.includes(location.state.source)) {
      return true;
    }

    if (!('token' in location.state) || location.state.token === '') {
      return true;
    }

    return false;
  }, [location.state, validSources]);

  const submitting = (formData) => {
    setFormState({
      ...formData,
      isSubmitting: true,
      errorMessage: null,
    });
  };

  const signin = (json) => {
    authDispatch({
      type: 'signin',
      payload: json,
    });
  };

  const formError = (error, formData) => {
    setFormState({
      ...formData,
      isSubmitting: false,
      errorMessage: error,
    });
  };

  const onSubmit = (formData) => {
    submitting(formData);

    fetch(`http://localhost:8081/${location.state.source}/register`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: formData.username,
        id_token: location.state.token,
      }),
    })
      .then((response) => {
        response.json().then((json) => {
          if (response.ok) {
            signin(json);
          } else {
            formError(json.error, formData);
          }
        });
      })
      .catch((error) => {
        formError(error.message || error.statusText, formData);
      });
  };

  useEffect(() => {
    if (authState.isAuthenticated) {
      history.push('/');
    }
  }, [authState, history]);

  if (invalidState()) {
    return <Redirect to="/account/signin" />;
  }

  return (
    <RegisterCont>
      <PageHeader>Select a Username</PageHeader>
      <p>
        In order to create an account on Taboset using your {location.state.source} account, please choose a username
        for your account.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item>
            <TextField
              inputRef={register({ required: 'Username is required!' })}
              label="Username"
              name="username"
              error={!!errors.username}
              helperText={<ErrorMessage errors={errors} name="username" />}
            />
          </Grid>

          {formState.errorMessage && (
            <Grid item>
              <span className="form-error">{formState.errorMessage}</span>
            </Grid>
          )}

          <Grid item>
            <Button variant="contained" color="primary" type="submit" disabled={formState.isSubmitting}>
              {formState.isSubmitting ? 'Creating...' : 'Register'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </RegisterCont>
  );
};

export default SelectUsernamePage;
