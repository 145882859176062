import gql from 'graphql-tag';

import { AlertStatus } from './alert-status.query';

export const Alert = {
  fragments: {
    alert: {}
  }
};

Alert.fragments = {
  alert: gql`
    fragment alertFields on alert {
      id
      created_at
      updated_at
      title
      description
    }
  `
};

export const ALERTS_QUERY_LATEST = gql`
  query Alert {
    alert(where: {alert_status: {name: {_eq: "Active"}}}, order_by: {created_at: desc}) {
      ...alertFields
      alert_status {
        ...alertStatusFields
      }
    }
  }
  ${Alert.fragments.alert}
  ${AlertStatus.fragments.alert_status}
`;
