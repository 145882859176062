import gql from 'graphql-tag';

export const MUTATION_CAMPAIGN_CHARACTER_ADD = gql`
mutation ($campaign_character: [campaign_character_insert_input!]!) {
  insert_campaign_character(objects: $campaign_character) {
    affected_rows
    returning {
      id
    }
  }
}
`;

