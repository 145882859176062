import React from 'react';

import styled from 'styled-components';

import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  padding: 0.5em 1em;
  margin-right: 2em;
`;

const Image = styled.img`
  height: 36px;
  cursor: pointer;

  ${props => props.theme.breakpoints.down('sm')} {
    height: 24px;
  }
`;

const HeaderLogo = () => (
  <Container>
    <Link to="/">
      <Image src="assets/logo.png" />
    </Link>
  </Container>
);

export default HeaderLogo;
