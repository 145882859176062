import React from 'react';

import styled from 'styled-components';

import { withRouter } from 'react-router-dom';

const AccountHomeCont = styled.div``;

export const AccountHomePage = () => {
  return <AccountHomeCont>Account Home Page</AccountHomeCont>;
};

export default withRouter(AccountHomePage);
