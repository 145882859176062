import gql from 'graphql-tag';

import { User } from './user.query';
import { Character } from './character.query';

export const Campaign = {
  fragments: {
    campaign: {}
  }
};

Campaign.fragments = {
  campaign: gql`
    fragment campaignFields on campaign {
      id
      name
      owner_uuid
      created_at
      updated_at
      max_number_of_players
    }
  `
};

export const CAMPAIGNS_QUERY = gql`
  query {
    campaign {
      ...campaignFields
      owner {
        ...userFields
      }
    }
  }
  ${Campaign.fragments.campaign}
  ${User.fragments.user}
`;

export const CAMPAIGNS_WITH_CHARACTERS_QUERY = gql`
  query CampaignsWithCharacters($withCharacters: Boolean! = false) {
    campaign {
      ...campaignFields
      owner {
        ...userFields
      }
      campaign_characters_aggregate {
        aggregate {
          count
        }
        nodes @include(if: $withCharacters) {
          id
          user {
            ...userFields
          }
          character {
            ...characterFields
          }
        }
      }
    }
  }
  ${Campaign.fragments.campaign}
  ${User.fragments.user}
  ${Character.fragments.character}
`;

export const MY_CAMPAIGNS_QUERY = gql`
  query Campaign($id: uuid!, $withCharacters: Boolean! = false) {
    campaign(where: { owner_uuid: { _eq: $id } }) {
      ...campaignFields
      owner {
        ...userFields
      }
      campaign_characters_aggregate {
        aggregate {
          count
        }
        nodes @include(if: $withCharacters) {
          id
          user {
            ...userFields
          }
          character {
            ...characterFields
          }
        }
      }
    }
  }
  ${Campaign.fragments.campaign}
  ${User.fragments.user}
  ${Character.fragments.character}
`;

export const MEMBER_OF_CAMPAIGNS_QUERY = gql`
  query Campaign($id: uuid!, $withCharacters: Boolean! = false) {
    campaign(where: { campaign_characters: { user_id: { _eq: $id } } }) {
      ...campaignFields
      owner {
        ...userFields
      }
      campaign_characters_aggregate {
        aggregate {
          count
        }
        nodes @include(if: $withCharacters) {
          id
          user {
            ...userFields
          }
          character {
            ...characterFields
          }
        }
      }
    }
  }
  ${Campaign.fragments.campaign}
  ${User.fragments.user}
  ${Character.fragments.character}
`;

export const MEMBER_OF_OR_MY_CAMPAIGNS_QUERY = gql`
  query Campaign($id: uuid!, $withCharacters: Boolean! = false) {
    campaign(
      where: {
        _or: [
          { owner_uuid: { _eq: $id } }
          { campaign_characters: { user_id: { _eq: $id } } }
        ]
      }
    ) {
      ...campaignFields
      owner {
        ...userFields
      }
      campaign_characters_aggregate {
        aggregate {
          count
        }
        nodes @include(if: $withCharacters) {
          id
          user {
            ...userFields
          }
          character {
            ...characterFields
          }
        }
      }
    }
  }
  ${Campaign.fragments.campaign}
  ${User.fragments.user}
  ${Character.fragments.character}
`;

export const CAMPAIGN_QUERY_BY_ID = gql`
  query Campaign($id: uuid!) {
    campaign(where: { id: { _eq: $id } }) {
      ...campaignFields
    }
  }
  ${Campaign.fragments.campaign}
`;


export const CAMPAIGN_QUERY_BY_ID_ALL = gql`
  query Campaign($id: uuid!) {
    campaign(where: { id: { _eq: $id } }) {
      ...campaignFields
      owner {
        ...userFields
      }
    }
  }
  ${Campaign.fragments.campaign}
  ${User.fragments.user}
`;

