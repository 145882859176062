import gql from 'graphql-tag';

export const User = {
  fragments: {
    user: {}
  }
};

User.fragments = {
  user: gql`
    fragment userFields on user {
      id
      username
    }
  `,
};

export const USER_QUERY = gql`
  query {
    user {
      ...userFields
    }
  }
  ${User.fragments.user}
`;

export const USER_QUERY_ALL = gql`
  query {
    user {
      ...userFields
    }
  }
  ${User.fragments.user}
`;

export const USER_QUERY_BY_ID = gql`
  query Campaign($id: uuid!) {
    user(where: {id: {_eq: $id}}) {
      ...userFields
    }
  }
  ${User.fragments.user}
`;
