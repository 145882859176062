import React, { useEffect } from 'react';

import styled from 'styled-components';

import { useForm, ErrorMessage } from 'react-hook-form';

import { useHistory } from 'react-router-dom';

import { TextField, Grid, Typography } from '@material-ui/core';

import PageHeader from '../../../components/common/page-header/page-header';

import { GoogleLoginButton } from '../../../components/auth/google-button';

import { AuthContext } from '../../../contexts/auth';

import { SubmitButton } from '@taboset/shared-ui';

import { Link } from 'react-router-dom';

const SigninCont = styled.div``;

export const SigninPage = () => {
  const { authState, authDispatch } = React.useContext(AuthContext);
  const initialState = {
    username: '',
    password: '',
    isSubmitting: false,
    errorMessage: null,
  };

  const [formState, setFormState] = React.useState(initialState);

  const { register, handleSubmit, errors } = useForm();

  const history = useHistory();

  const submitting = (formData) => {
    setFormState({
      ...formData,
      isSubmitting: true,
      errorMessage: null,
    });
  };

  const signin = (json) => {
    authDispatch({
      type: 'signin',
      payload: json,
    });
  };

  const formError = (error, formData) => {
    setFormState({
      ...formData,
      isSubmitting: false,
      errorMessage: error,
    });
  };

  const onGoogleLoginResponse = (googleResponse) => {
    fetch('http://localhost:8081/google/signin', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id_token: googleResponse.tokenId,
      }),
    })
      .then((response) => {
        response.json().then((json) => {
          if (response.status === 200) {
            signin(json);
          } else if (response.status === 400 && json.error === 'Unknown user') {
            history.push('/account/select-username', {
              source: 'google',
              token: googleResponse.tokenId,
            });
          } else {
            // TODO: Error Messsage
            console.log('Unhandled error');
          }
        });
      })
      .catch((error) => {
        // TODO: Error Messsage
        console.log('Unhandled error');
      });
  };

  const onSubmit = (formData) => {
    submitting(formData);

    fetch('http://localhost:8081/signin', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: formData.username,
        password: formData.password,
      }),
    })
      .then((response) => {
        response.json().then((json) => {
          if (response.ok) {
            signin(json);
          } else {
            formError(json.error, formData);
          }
        });
      })
      .catch((error) => {
        formError(error.message || error.statusText, formData);
      });
  };

  useEffect(() => {
    if (authState.isAuthenticated) {
      history.push('/');
    }
  }, [authState, history]);

  return (
    <SigninCont>
      <PageHeader>Sign In</PageHeader>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item>
            <TextField
              inputRef={register({ required: 'Username is required!' })}
              label="Username"
              name="username"
              error={!!errors.username}
              helperText={<ErrorMessage errors={errors} name="username" />}
            />
          </Grid>
          <Grid item>
            <TextField
              inputRef={register({
                required: 'Password is required!',
              })}
              label="Password"
              name="password"
              type="password"
              error={!!errors.password}
              helperText={<ErrorMessage errors={errors} name="password" />}
            />
          </Grid>
          {formState.errorMessage && (
            <Grid item>
              <span className="form-error">{formState.errorMessage}</span>
            </Grid>
          )}
          <Grid item>
            <SubmitButton variant="contained" color="primary" type="submit" disabled={formState.isSubmitting}>
              {formState.isSubmitting ? 'Loading...' : 'Sign In'}
            </SubmitButton>
          </Grid>
          <Grid item style={{ marginTop: '2em' }}>
            <Link to="/account/forgot-password">Forgot Password?</Link>
          </Grid>
        </form>
        <Typography variant="overline" display="block" gutterBottom>
          Social Login Providers
        </Typography>
        <GoogleLoginButton onResponse={onGoogleLoginResponse} />
      </Grid>
    </SigninCont>
  );
};

export default SigninPage;
