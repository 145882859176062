import React from 'react';

import styled from 'styled-components';

import Box from '@material-ui/core/Box';

const StyledFooterTop = styled.div`
  background-color: #151310;
`;

const StyledFooterBottom = styled.div`
  background-color: #434140;
`;

const InnerFooter = styled(Box)`
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  color: #fff;
`;

export const Footer = () => {
  return (
    <>
      <StyledFooterTop>
        <InnerFooter
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
        >
          Top Footer
        </InnerFooter>
      </StyledFooterTop>
      <StyledFooterBottom>
        <InnerFooter
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
        >
          Bottom Footer
        </InnerFooter>
      </StyledFooterBottom>
    </>
  );
};

export default Footer;
