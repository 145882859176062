import React from 'react';

import { Discord, Twitter, Facebook, Youtube, Twitch } from 'styled-icons/fa-brands';

import { HeaderLinks } from '../links';

import { IconButton } from '@taboset/shared-ui';

export const HeaderSocial = () => {
  return (
    <HeaderLinks>
      <IconButton borderRight href="https://discord.gg/xn86AdZ">
        <Discord />
      </IconButton>
      <IconButton href="https://twitter.com/tabosetplatform">
        <Twitter />
      </IconButton>
      <IconButton href="https://www.facebook.com/taboset">
        <Facebook />
      </IconButton>
      <IconButton href="https://www.youtube.com/taboset">
        <Youtube />
      </IconButton>
      <IconButton href="https://www.twitch.com/taboset">
        <Twitch />
      </IconButton>
    </HeaderLinks>
  );
};

export default HeaderSocial;
