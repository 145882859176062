import gql from 'graphql-tag';

export const MUTATION_CHARACTER_ADD = gql`
  mutation ($character: [character_insert_input!]!) {
    insert_character(objects: $character) {
      affected_rows
      returning {
        id
        name
      }
    }
  }
`;

export const MUTATION_CHARACTER_UPDATE = gql`
  mutation ($id: Int!, $name: String!) {
    update_character(
      where: { id: { _eq: $id } }
      _set: {
        name: $name
      }
    ) {
      affected_rows
    }
  }
`;

export const MUTATION_CHARACTER_DELETE = gql`
  mutation ($id: Int!) {
    delete_character(
      where: {
        id: {
          _eq: $id
        }
      }
    ) {
      affected_rows
    }
  }
`;
