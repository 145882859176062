import { not, guardFactory, Requirement } from 'react-rbac-guard';

export class TabosetRequirement extends Requirement {
  requirement: any;
  constructor(requirement) {
    super();
    this.requirement = requirement;
  }

  isSatisfied(credentials) {
    return credentials.includes(this.requirement);
  }
}

export const NeedAdmin = new TabosetRequirement('admin');
export const NeedManager = new TabosetRequirement('manager');
export const NeedUser = new TabosetRequirement('user');
export const NeedGuest = new TabosetRequirement('guest');

export const PermAdmin = guardFactory(NeedAdmin);
export const PermManager = guardFactory(NeedManager);
export const PermUser = guardFactory(NeedUser);
export const PermGuest = guardFactory(NeedGuest);
export const PermAuthorized = guardFactory(not(NeedGuest));
