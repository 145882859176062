import styled from 'styled-components';

interface HeaderLinkProps {
  borderLeft?: boolean;
  borderRight?: boolean;
}

export const HeaderLinks = styled.div<any>`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0em;
  padding: 0em 1em;

  ${(props) =>
    props.borderLeft &&
    `
      border-left: 1px solid #333;
    `}

  ${(props) =>
    props.borderRight &&
    `
    border-right: 1px solid #333;
  `}

  a {
    height: 20px;
    width: 20px;
    color: #fff;
    margin: 0.25em;
    padding: 0.5em;
  }
`;
