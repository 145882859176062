import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import App from './app/app';
import Spinner from './app/components/common/spinner/spinner';

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <App />
  </Suspense>,
  document.getElementById('root'),
);
