import React from 'react';

import moment from 'moment';
import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';

import { useQuery } from '@apollo/react-hooks';

import { useParams, withRouter } from 'react-router-dom';

import { NEWS_QUERY_BY_ID } from '@taboset/graphql';

/* eslint-disable-next-line */
export interface NewsViewProps { }

const useStyles = makeStyles((theme) => ({
  root: { color: 'black' },
}))

export const NewsView = (props: NewsViewProps) => {
  const { newsId } = useParams();

  const { loading: queryLoading, error, data, refetch, networkStatus } = useQuery(NEWS_QUERY_BY_ID, { // eslint-disable-line
    variables: { id: Number(newsId) }
  });

  const classes = useStyles()

  if (queryLoading) return <p>Loading...</p>;
  if (error) return (
    <>
      <h1>There was an error fetching the news</h1>
      <p>
        {error.message}
      </p>
      <pre>Bad: {error.graphQLErrors.map(({ message }, i) => (
        <span key={i}>{message}</span>
      ))}
      </pre>
    </>
  )

  const news = data.news[0];

  return (
    <Grid container justify='center' className={classes.root}>
      <Grid item xs={8}>
        <Card className={classes.root}>
          <CardHeader
            title={news.title}
            subheader={moment(news.updated_at).format('MMM Do')}
            data-testid="news-title"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2" data-testid="news-description">
              {news.description}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" data-testid="news-content">
              {news.content}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );


  
};

export default withRouter(NewsView);
