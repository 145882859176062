import gql from 'graphql-tag';

import { User } from './user.query';

export const Character = {
  fragments: {
    character: {},
    race: {}
  }
};

Character.fragments = {
  character: gql`
    fragment characterFields on character {
      id
      name
      race_id
      user_id
      created_at
      updated_at
    }
  `,
  race: gql`
  fragment raceFields on race {
    id
    name
  }
  `,
};

export const CHARACTERS_QUERY = gql`
  query {
    character {
      ...characterFields
    }
  }
  ${Character.fragments.character}
`;

export const CHARACTERS_QUERY_ALL = gql`
  query {
    character {
      ...characterFields
      user {
        ...userFields
      }
      race {
        ...raceFields
      }
    }
  }
  ${Character.fragments.character}
  ${User.fragments.user}
  ${Character.fragments.race}
`;

export const CHARACTER_QUERY_BY_ID = gql`
  query Character($id: Int!) {
    character(where: {id: {_eq: $id}}) {
      ...characterFields
    }
  }
  ${Character.fragments.character}
`;

export const CHARACTER_QUERY_BY_ID_ALL = gql`
  query Character($id: Int!) {
    character(where: {id: {_eq: $id}}) {
      ...characterFields
      user {
        ...userFields
      }
      race {
        ...raceFields
      }
    }
  }
  ${Character.fragments.character}
  ${User.fragments.user}
  ${Character.fragments.race}
`;
