
import { createGlobalStyle } from 'styled-components';


const GlobalStyle = createGlobalStyle`
  body {
    font-size: 0.875rem;
  }

  html, body {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
    background: transparent;

    font-family: 'Roboto', sans-serif;
    color: #2b3438;

    #root {
      height: 100%;
      width: 100%;

      display: flex;
      flex-direction: column;
    }

    a {
      text-decoration: none;
    }
  }
`;

export default GlobalStyle;
