import React from 'react';

import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const MenuCont = styled.div`
  display: flex;
`;

const MenuItem = styled(Link)`
  font-size: 1.2rem;
  padding: 1rem 1.5rem;
  color: #a5afba;
  text-decoration: none;

  &:first-child {
    padding-left: 0;
  }

  &:hover {
    color: #fff;
  }
`;

export const HeaderMenu = () => {
  const { t } = useTranslation(['common']);

  return (
    <MenuCont>
      <MenuItem to="/campaigns">{t('campaigns')}</MenuItem>
      <MenuItem to="/characters">{t('characters')}</MenuItem>
      <MenuItem to="/news">{t('news')}</MenuItem>
    </MenuCont>
  );
};

export default HeaderMenu;
