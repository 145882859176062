import React, { useEffect } from 'react';

import styled from 'styled-components';

import { useForm, ErrorMessage } from 'react-hook-form';

import { useHistory } from 'react-router-dom';

import { TextField, Grid, Button } from '@material-ui/core';

import PageHeader from '../../../components/common/page-header/page-header';

import { AuthContext } from '../../../contexts/auth';

const RegisterCont = styled.div``;

export const RegisterPage = () => {
  const { authState, authDispatch } = React.useContext(AuthContext);
  const initialState = {
    username: '',
    password: '',
    isSubmitting: false,
    errorMessage: null
  };

  const [formState, setFormState] = React.useState(initialState);

  const { register, handleSubmit, errors } = useForm();

  const history = useHistory();

  const onSubmit = formData => {
    setFormState({
      ...formData,
      isSubmitting: true,
      errorMessage: null
    });

    fetch('http://localhost:8081/register', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: formData.username,
        password: formData.password
      })
    })
      .then(response => {
        response.json().then(json => {
          if (response.ok) {
            authDispatch({
              type: 'signin',
              payload: json
            });
          } else {
            setFormState({
              ...formData,
              isSubmitting: false,
              errorMessage: json.error
            });
          }
        });
      })
      .catch(error => {
        setFormState({
          ...formData,
          isSubmitting: false,
          errorMessage: error.message || error.statusText
        });
      });
  };

  useEffect(() => {
    if (authState.isAuthenticated) {
      history.push('/');
    }
  }, [authState, history]);

  return (
    <RegisterCont>
      <PageHeader>Create a Free Account</PageHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item>
            <TextField
              inputRef={register({ required: 'Username is required!' })}
              label="Username"
              name="username"
              error={!!errors.username}
              helperText={<ErrorMessage errors={errors} name="username" />}
            />
          </Grid>

          <Grid item>
            <TextField
              inputRef={register({
                required: 'Email Address is required!'
              })}
              label="Email Address"
              name="email"
              error={!!errors.email}
              helperText={<ErrorMessage errors={errors} name="email" />}
            />
          </Grid>

          <Grid item>
            <TextField
              inputRef={register({
                required: 'Password is required!'
              })}
              label="Password"
              name="password"
              type="password"
              error={!!errors.password}
              helperText={<ErrorMessage errors={errors} name="password" />}
            />
          </Grid>

          {formState.errorMessage && (
            <Grid item>
              <span className="form-error">{formState.errorMessage}</span>
            </Grid>
          )}

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={formState.isSubmitting}
            >
              {formState.isSubmitting ? 'Creating...' : 'Register'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </RegisterCont>
  );
};

export default RegisterPage;
