import React, { useContext } from 'react';

import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../../contexts/auth';

export default function SignedInGuardedRoute({
  component: Component,
  ...rest
}) {
  const { authState } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={props =>
        authState.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/account/signin" />
        )
      }
    />
  );
}
