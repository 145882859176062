import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { withRouter, Link } from 'react-router-dom';

import { CHARACTERS_QUERY, MUTATION_CHARACTER_DELETE } from '@taboset/graphql';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { Button } from '@material-ui/core';
import { Grid, Table, TableHeaderRow, TableColumnResizing } from '@devexpress/dx-react-grid-material-ui';
import { TableColumnWidthInfo, SortingState, IntegratedSorting, Sorting } from '@devexpress/dx-react-grid';

import PageHeader from '../../../components/common/page-header/page-header';

const StyledCharacters = styled.div``;

const Action = styled(Button)`
  margin-right: 1rem;

  &:last-child {
    margin-right: 0rem;
  }
`;

const resourceKey = 'character';

export const CharactersList = (props: any) => { // eslint-disable-line
  const { loading: queryLoading, error, data, refetch } = useQuery(CHARACTERS_QUERY);

  const [deleteCharacter] = useMutation(MUTATION_CHARACTER_DELETE,
    {
      onCompleted({ delete_character: { affected_rows: deleted } }) {
        refetch();
      }
    });

  const handleDeleteCharacter = (id: string) => {
    deleteCharacter({ variables: { id: id } });
  }


  const pageHeaderActions = [
    {
      title: 'Add character',
      href: '/characters/add/',
    },
  ];

  const [defaultColumnWidths] = useState<TableColumnWidthInfo[]>([
    { columnName: 'name', width: 'auto' },
    { columnName: 'actions', width: 300 },
  ]);

  const [columnWidths, setColumnWidths] = useState(defaultColumnWidths);

  const [sorting, setSorting] = useState<Sorting[]>([{ columnName: 'name', direction: 'asc' }]);

  const [sortingStateColumnExtensions] = useState([{ columnName: 'actions', sortingEnabled: false }]);

  const columns = [
    { name: 'name', title: 'Character Name' },
    {
      name: 'actions',
      title: 'Actions',
      getCellValue: (row) => {
        return (
          <>
            <Action variant="contained" color="primary" href={`/characters/${row.id}`}>
              View
            </Action>
            <>
              <Action variant="contained" color="primary" href={`/characters/${row.id}/edit`}>
                Edit
                </Action>
              <Action
                variant="contained"
                color="primary"
                onClick={() => {
                  handleDeleteCharacter(row);
                }}
              >
                Delete
                </Action>
            </>
          </>
        );
      },
    },
  ];

  /*eslint-disable */
  useEffect(() => {
    refetch();
  }, [])
  /*eslint-enable */

  if (queryLoading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <StyledCharacters>
      <PageHeader actions={pageHeaderActions}>Characters</PageHeader>

      <Grid rows={data[resourceKey].map(({ __typename, ...item }) => item)} columns={columns}>
        <SortingState sorting={sorting} onSortingChange={setSorting} columnExtensions={sortingStateColumnExtensions} />
        <IntegratedSorting />
        <Table />
        <TableColumnResizing
          columnWidths={columnWidths}
          minColumnWidth={100}
          resizingMode="nextColumn"
          onColumnWidthsChange={setColumnWidths}
        />
        <TableHeaderRow showSortingControls />
      </Grid>
    </StyledCharacters>
  );
};

export default withRouter(CharactersList);
