import React from 'react';

import styled from 'styled-components';

const StyledAnchor = styled.a`
    height: 20px;
    width: 20px;
    color: #fff;
    margin: 0.25em;
    padding: 0.5em;
`

export const IconButton = (props: any) => {

  return (
    <StyledAnchor target="_new" href={props.href}>
      {props.children}
    </StyledAnchor>
  );
};

export default IconButton;
