import React from 'react';

import styled from 'styled-components';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import GlobalStyle from '../global-styles/global-styles';

/* eslint-disable-next-line */
export interface SpinnerProps { }

const StyledSpinnerWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 100%;
`;

const StyledLoading = styled(Typography)`
  text-transform: uppercase;
}
`

export const Spinner = (props: SpinnerProps) => {
  return (
    <StyledSpinnerWrapper>
      <GlobalStyle />
      <StyledLoading variant="h5" gutterBottom data-testid="spinner-id">Loading</StyledLoading>
      <CircularProgress disableShrink />
    </StyledSpinnerWrapper>
  );
};

export default Spinner;
