import React from 'react';

import styled from 'styled-components';

import { useForm, ErrorMessage } from 'react-hook-form';

import { useHistory } from 'react-router-dom';

import { TextField, Grid, Button, Typography } from '@material-ui/core';

import PageHeader from '../../../components/common/page-header/page-header';

/* eslint-disable-next-line */
export interface ForgotPasswordPageProps { }

const ForgotPasswordContainer = styled.div``;


export const ForgotPasswordPage = (props: ForgotPasswordPageProps) => {
  const initialState = {
    email_address: '',
    isSubmitting: false,
    errorMessage: null
  };

  const submitting = (formData) => {
    setFormState({
      ...formData,
      isSubmitting: true,
      errorMessage: null,
    });
  };

  const formError = (error, formData) => {
    setFormState({
      ...formData,
      isSubmitting: false,
      errorMessage: error,
    });
  };

  const [formState, setFormState] = React.useState(initialState);

  const { register, handleSubmit, errors } = useForm();

  const history = useHistory();

  const onSubmit = (formData) => {
    submitting(formData);

    history.push('/account/signin');
  }


  return (
    <ForgotPasswordContainer>
      <PageHeader>Forgot Password</PageHeader>
      <Typography variant="overline" display="block" gutterBottom>
        Please enter your email address to request a password reset.
      </Typography>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item>
            <TextField
              style={{ marginBottom: '1em' }}
              inputRef={register(
                {
                  required: 'Email is required!',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "invalid email address"
                  }
                })
              }
              label="Email"
              name="email_address"
              error={!!errors.email_address}
              helperText={<ErrorMessage errors={errors} name="email_address" />}
            />
          </Grid>
          {formState.errorMessage && (
            <Grid item>
              <span className="form-error">{formState.errorMessage}</span>
            </Grid>
          )}
          <Grid item>
            <Button variant="contained" color="primary" type="submit" disabled={formState.isSubmitting}>
              {formState.isSubmitting ? 'Loading...' : 'Send'}
            </Button>
          </Grid>
        </form>
      </Grid>
    </ForgotPasswordContainer>
  );
};

export default ForgotPasswordPage;
