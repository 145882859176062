import React from 'react';

import { Link } from 'react-router-dom';

import { History } from 'styled-icons/fa-solid';
import { Bell } from 'styled-icons/fa-solid';
import { MessageAlt } from 'styled-icons/boxicons-solid';

import { HeaderLinks } from '../links';

export const HeaderComms = () => {
  return (
    <HeaderLinks borderRight>
      <Link to="/changelog">
        <History />
      </Link>
      <Link to="/notifications">
        <Bell />
      </Link>
      <Link to="/messages">
        <MessageAlt />
      </Link>
    </HeaderLinks>
  );
};

export default HeaderComms;
