import React from 'react';

import styled, { ThemeProvider as SCThemeProvider } from 'styled-components';

import { createMuiTheme, MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';

import { ApolloProvider } from '@apollo/react-hooks';

import ApolloClient from 'apollo-boost';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { CredentialProvider } from 'react-rbac-guard';

import Header from './components/header/header';
import Footer from './components/footer/footer';

import HomePage from './pages/home/home';
import Account from './pages/account/account';
import Campaigns from './pages/campaigns/campaigns';
import Characters from './pages/characters/characters';
import News from './pages/news/news';

import { AuthContext } from './contexts/auth';

import SignedInGuardedRoute from './components/auth/signed-in-guarded-route';

import GlobalStyle from './components/common/global-styles/global-styles';

import './i18n';

import { useTranslation } from 'react-i18next';

const GRAPHQL_URL = 'http://localhost:8080/v1alpha1/graphql';

const apolloClient = new ApolloClient({
  uri: GRAPHQL_URL,
  request: (operation) => {
    const token = localStorage.getItem('token');
    if (token) {
      operation.setContext({
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      });
    }
  },
});

const muiTheme = createMuiTheme({});

const PageContainer = styled.div`
  flex-grow: 1;
`;

const PageContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;

  padding: 1rem 0rem;

  ${(props) => props.theme.breakpoints.down('md')} {
    padding: 1rem 1rem;
  }
`;

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  roles: ['guest'],
};

interface AuthenticationState {
  isAuthenticated: boolean;
  user: any;
  token: string;
  roles: string[];
}

type AuthenticationAction = { type: 'signin'; payload: any } | { type: 'signout' };

const reducer = (currentState: AuthenticationState, action: AuthenticationAction) => {
  switch (action.type) {
    case 'signin':
      localStorage.setItem('user', JSON.stringify(action.payload));
      localStorage.setItem('token', action.payload.token);
      apolloClient.clearStore();
      return {
        ...currentState,
        isAuthenticated: true,
        user: action.payload,
        token: action.payload.token,
        roles: action.payload.roles,
      };
    case 'signout':
      localStorage.clear();
      apolloClient.clearStore();
      return {
        ...currentState,
        isAuthenticated: false,
        user: null,
        roles: ['guest'],
      };
    default:
      return currentState;
  }
};

export const App = () => {
  if (localStorage.getItem('user')) {
    initialState.isAuthenticated = true;
    initialState.user = JSON.parse(localStorage.getItem('user'));
    initialState.roles = initialState.user.roles;
    initialState.token = localStorage.getItem('token');
  }

  const [authState, authDispatch] = React.useReducer(reducer, initialState);

  const { i18n } = useTranslation();

  const baseName = i18n.language === 'en' ? '' : `/${i18n.language}`;

  return (
    <AuthContext.Provider
      value={{
        authState,
        authDispatch,
      }}
    >
      <ApolloProvider client={apolloClient}>
        <CredentialProvider value={authState.roles}>
          <StylesProvider injectFirst>
            <MuiThemeProvider theme={muiTheme}>
              <SCThemeProvider theme={muiTheme}>
                <GlobalStyle />
                <BrowserRouter basename={baseName}>
                  <Header />
                  <PageContainer>
                    <PageContent>
                      <Switch>
                        <SignedInGuardedRoute path="/campaigns" component={Campaigns} />
                        <SignedInGuardedRoute path="/characters" component={Characters} />
                        <Route path="/news" component={News} />
                        <Route path="/account" component={Account} />
                        <Route path="/" component={HomePage} />
                      </Switch>
                    </PageContent>
                  </PageContainer>
                  <Footer />
                </BrowserRouter>
              </SCThemeProvider>
            </MuiThemeProvider>
          </StylesProvider>
        </CredentialProvider>
      </ApolloProvider>
    </AuthContext.Provider>
  );
};

export default App;
