import gql from 'graphql-tag';

export const News = {
  fragments: {
    news: {}
  }
};

News.fragments = {
  news: gql`
    fragment newsFields on news {
      id
      title
      created_at
      updated_at
    }
  `
};

export const NEWS_QUERY = gql`
  query {
    news {
      ...newsFields
    }
  }
  ${News.fragments.news}
`;

export const NEWS_QUERY_BY_ID = gql`
  query News($id: Int!) {
    news(where: {id: {_eq: $id}}) {
      ...newsFields
      description
      content
    }
  }
  ${News.fragments.news}
`;

export const NEWS_GET_LATEST = gql`
  query News {
    news(limit: 1, order_by: {created_at: desc}) {
      ...newsFields
      description
      content
    }
  }
  ${News.fragments.news}
`;