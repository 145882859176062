import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import Backend from 'i18next-http-backend';

import { LANGUAGES } from './constants';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .init({
    ns: ['common', 'campaigns', 'characters', 'news'],
    defaultNS: 'common',

    backend: {
      loadPath: './assets/locales/{{lng}}/{{ns}}.json',
    },

    fallbackLng: 'en',

    whitelist: LANGUAGES,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    detection: {
      order: ['path'],
      checkWhitelist: true,
    },
  });

export default i18n;
