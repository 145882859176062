import React, { useContext } from 'react';

import styled from 'styled-components';

import { useHistory, Link } from 'react-router-dom';

import { Menu, MenuItem, Button } from '@material-ui/core';

import { DiceD20 } from 'styled-icons/fa-solid/';

import { AuthContext } from '../../../contexts/auth';

const HeaderUserButton = styled(Button)`
  color: #fff;
  margin: 0 1rem;
`;

const SignedInContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 1rem;
  }
`;

const Username = styled.div``;

const UserMenu = styled(Menu)`
  margin-top: 2.5rem;
`;

export const HeaderUser = () => {
  const { authState, authDispatch } = useContext(AuthContext);

  const history = useHistory();

  const signout = () => {
    handleClose();

    authDispatch({
      type: 'signout',
    });

    history.push('/account/signin');
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {!authState.isAuthenticated && (
        <>
          <HeaderUserButton href="/account/signin/">Sign In</HeaderUserButton>
          <HeaderUserButton href="/account/register/">Create a Free Account</HeaderUserButton>
        </>
      )}

      {authState.isAuthenticated && (
        <SignedInContainer>
          <HeaderUserButton aria-haspopup="true" onClick={handleClick}>
            <DiceD20 />
            <Username>{authState.user.username}</Username>
          </HeaderUserButton>
          <UserMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={handleClose} component={Link} to="/account/profile/">
              Profile
            </MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/account/">
              My account
            </MenuItem>
            <MenuItem onClick={signout}>Logout</MenuItem>
          </UserMenu>
        </SignedInContainer>
      )}
    </>
  );
};

export default HeaderUser;
