import React from 'react';

import styled from 'styled-components';

import Box from '@material-ui/core/Box';

import HeaderLogo from './logo/logo';
import HeaderSearchIcon from './search/search-icon';
import HeaderSearchBox from './search/search-box';
import HeaderSocial from './social/social';
import HeaderComms from './comms/comms';
import HeaderUser from './user/user';
import HeaderMobileMenu from './mobile-menu/mobile-menu';
import HeaderMenu from './menu/menu';
import HeaderLanguage from './language/language';
import HeaderAdmin from './admin/admin';

import { PermAuthorized, PermAdmin } from '../auth/requirements';

const StyledHeader = styled.div`
  background-color: #151310;
  border-bottom: 1px solid #fff;
  color: #fff;
  height: 60px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    height: 40px;
  }
`;

const StyledMenuHeader = styled(StyledHeader)`
  background-color: #26282a;
  height: auto;

  ${(props) => props.theme.breakpoints.down('md')} {
    padding-left: 1em;
  }
`;

const InnerHeader = styled(Box)`
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
`;

export const Header = () => {
  return (
    <>
      <StyledHeader>
        <InnerHeader display="flex" justifyContent="space-between" alignItems="stretch" height="100%">
          <HeaderLogo />
          <Box
            flexGrow={1}
            justifyContent="space-between"
            alignItems="stretch"
            display={{ xs: 'none', sm: 'none', md: 'flex' }}
          >
            <Box flexGrow={1} display="flex">
              <HeaderSearchIcon />
              <HeaderSearchBox />
            </Box>
            <HeaderSocial />
            <PermAuthorized>
              <HeaderComms />
            </PermAuthorized>
            <HeaderLanguage />
            <HeaderUser />
            <PermAdmin>
              <HeaderAdmin />
            </PermAdmin>
          </Box>
          <Box display={{ xs: 'flex', sm: 'flex', md: 'none' }} height="100%">
            <HeaderSearchIcon />
            <HeaderMobileMenu />
          </Box>
        </InnerHeader>
      </StyledHeader>
      <StyledMenuHeader>
        <InnerHeader display="flex" justifyContent="space-between" alignItems="stretch">
          <HeaderMenu />
        </InnerHeader>
      </StyledMenuHeader>
    </>
  );
};

export default Header;
