import React from 'react';

import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { Search } from 'styled-icons/fa-solid';

import { useTheme, useMediaQuery } from '@material-ui/core';

const SearchIconCont = styled.div`
  display: flex;
  height: 100%;
  align-items: center;

  ${props => props.theme.breakpoints.down('sm')} {
    padding: 0em 1em 0em 1em;
    border-left: 1px solid #444;
    border-right: 1px solid #444;
  }

  svg {
    width: 24px;
    height: 24px;

    ${props => props.theme.breakpoints.down('sm')} {
      width: 20px;
      height: 20px;
    }

    color: #999;
  }
`;

export const HeaderSearchIcon = props => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const iconOnClick = e => {
    if (isSmall) {
      e.preventDefault();
    }
  };

  return (
    <SearchIconCont>
      <Link to="/search" onClick={iconOnClick}>
        <Search />
      </Link>
    </SearchIconCont>
  );
};

export default HeaderSearchIcon;
