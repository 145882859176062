import React, { useState, useContext } from 'react';

import styled from 'styled-components';

import { Button, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';

import { Menu } from 'styled-icons/material/';

import { BookAlt } from 'styled-icons/boxicons-solid/';
import { Person } from 'styled-icons/material-rounded/';
import { SignOut } from 'styled-icons/octicons/';

import { AuthContext } from '../../../contexts/auth';
import { useHistory } from 'react-router';

const MobileButton = styled(Button)`
  svg {
    width: 20px;
    height: 20px;
    color: #fff;
  }
`;

const MenuIcon = styled(ListItemIcon)`
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const HeaderMobileMenu = () => {
  const { authDispatch } = useContext(AuthContext);

  const history = useHistory();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const signout = () => {
    setDrawerOpen(false);

    authDispatch({
      type: 'signout',
    });

    history.push('/account/signin');
  };

  return (
    <>
      <MobileButton onClick={toggleDrawer(true)}>
        <Menu />
      </MobileButton>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          <ListItem button key="Campaigns">
            <MenuIcon>
              <BookAlt />
            </MenuIcon>
            <ListItemText primary="Campaigns" />
          </ListItem>
          <ListItem button key="Characters">
            <MenuIcon>
              <Person />
            </MenuIcon>
            <ListItemText primary="Characters" />
          </ListItem>
          <Divider />
          <ListItem button key="SignOut" onClick={signout}>
            <MenuIcon>
              <SignOut />
            </MenuIcon>
            <ListItemText primary="SignOut" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default HeaderMobileMenu;
