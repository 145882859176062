import React, { useState } from 'react';

import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { Tooltip, Button, Menu, MenuItem, Box, Divider } from '@material-ui/core';

import { Translate, ExpandMore } from 'styled-icons/material';

import { useLocation } from 'react-router-dom';

import { LANGUAGES_LABEL } from '../../../constants';
import { pathnameToLanguage } from '../../../utils/helpers';

const StyledHeaderLanguage = styled.div`
  display: flex;
  align-items: center;
  margin: 0em;
  padding: 0em 1em;
  border-right: 1px solid #333;
`;

const CurrentLanguageText = styled.span`
  ${({ theme }) => `
    margin: ${theme.spacing(0, 0.5, 0, 1)};
  `};

  color: #FFF:
  display: none;

  ${(props) => props.theme.breakpoints.up('md')} {
    display: 'block';
  }
`;

const LOCALES = { zh: 'zh-CN', pt: 'pt-BR', es: 'es-ES' };
const CROWDIN_ROOT_URL = 'https://translate.taboset.com/project/website/';

export const HeaderLanguage = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const [languageMenu, setLanguageMenu] = useState(null);

  const handleLanguageIconClick = (event) => {
    setLanguageMenu(event.currentTarget);
  };

  const handleLanguageMenuClose = (event) => {
    if (event.currentTarget.nodeName === 'A') {
      document.cookie = `userLanguage=noDefault;path=/;max-age=31536000`;
    }
    setLanguageMenu(null);
  };

  const crowdInLocale = LOCALES[i18n.language] || i18n.language;

  const { canonical } = pathnameToLanguage(location.pathname);

  return (
    <StyledHeaderLanguage>
      <Tooltip title={t('changeLanguage')} enterDelay={300}>
        <Button
          color="inherit"
          aria-owns={languageMenu ? 'language-menu' : undefined}
          aria-haspopup="true"
          aria-label={t('changeLanguage')}
          onClick={handleLanguageIconClick}
          data-ga-event-category="header"
          data-ga-event-action="language"
        >
          <Translate />
          <CurrentLanguageText>
            {i18n.language === 'aa'
              ? 'Translating'
              : LANGUAGES_LABEL.filter((language) => language.code === i18n.language)[0].text}
          </CurrentLanguageText>
          <ExpandMore fontSize="small" />
        </Button>
      </Tooltip>
      <Menu id="language-menu" anchorEl={languageMenu} open={Boolean(languageMenu)} onClose={handleLanguageMenuClose}>
        {LANGUAGES_LABEL.map((language) => (
          <MenuItem
            component="a"
            data-no-link="true"
            href={language.code === 'en' ? canonical : `/${language.code}${canonical}`}
            key={language.code}
            selected={i18n.language === language.code}
            onClick={handleLanguageMenuClose}
            lang={language.code}
            hrefLang={language.code}
          >
            {language.text}
          </MenuItem>
        ))}
        <Box my={1}>
          <Divider />
        </Box>
        <MenuItem
          component="a"
          data-no-link="true"
          href={
            i18n.language === 'en' || i18n.language === 'aa'
              ? `${CROWDIN_ROOT_URL}`
              : `${CROWDIN_ROOT_URL}${crowdInLocale}#/staging`
          }
          rel="noopener nofollow"
          target="_blank"
          key={i18n.language}
          lang={i18n.language}
          hrefLang="en"
          onClick={handleLanguageMenuClose}
        >
          {`${t('helpToTranslate')}`}
        </MenuItem>
      </Menu>
    </StyledHeaderLanguage>
  );
};

export default HeaderLanguage;
