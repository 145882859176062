import React, { useState } from 'react';

import styled from 'styled-components';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const SearchBoxCont = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: 1em;
`;

const SearchInput = styled.input`
  background-color: transparent;
  border: 0px;
  color: #fff;
  font-family: 'Roboto';
  font-size: 1.2rem;
  outline: none;
`;

export const HeaderSearchBox = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const [input, setInput] = useState('');

  history.listen(() => {
    setInput('');
  });

  const onKeyPress = (e) => {
    if (e.which === 13 && input.length > 0) {
      setInput('');

      history.push({
        pathname: '/search',
        search: `?q=${input}`,
      });
    }
  };

  return (
    <SearchBoxCont>
      <SearchInput
        placeholder={t('searchTheSite')}
        onKeyPress={onKeyPress}
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
    </SearchBoxCont>
  );
};

export default HeaderSearchBox;
