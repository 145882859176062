import React, { useEffect, useState, useContext } from 'react';

import styled from 'styled-components';

import { withRouter } from 'react-router-dom';

import {
  CAMPAIGNS_WITH_CHARACTERS_QUERY,
  MUTATION_CAMPAIGN_CHARACTER_ADD,
  MUTATION_CAMPAIGN_DELETE,
} from '@taboset/graphql';

import { useQuery, useMutation } from '@apollo/react-hooks';

import { Grid, Table, TableHeaderRow, TableColumnResizing } from '@devexpress/dx-react-grid-material-ui';

import { AuthContext} from '../../../contexts/auth'
import PageHeader from '../../../components/common/page-header/page-header';
import { Button } from '@material-ui/core';
import { TableColumnWidthInfo, SortingState, IntegratedSorting, Sorting } from '@devexpress/dx-react-grid';

const Action = styled(Button)`
  margin-right: 1rem;

  &:last-child {
    margin-right: 0rem;
  }
`;

const StyledCampaigns = styled.div``;

const resourceKey = 'campaign';


export const CampaignsList = () => {
  const { authState: { user: { id: myUserId } } } = useContext(AuthContext)
  const { loading: queryLoading, error, data, refetch } = useQuery(CAMPAIGNS_WITH_CHARACTERS_QUERY, {
    variables: {
      withCharacters: true,
    },
  });

  const [deleteCampaign] = useMutation(MUTATION_CAMPAIGN_DELETE, {
    onCompleted() {
      refetch();
    },
  });

  const [addCampaignCharacter] = useMutation(MUTATION_CAMPAIGN_CHARACTER_ADD, {
    onCompleted({
      insert_campaign_character: {
        affected_rows: inserted,
        returning: [campaignCharacter],
      },
    }) {
      refetch();
    },
  });

  const handleDeleteCampaign = (id: string) => {
    deleteCampaign({ variables: { id: id } });
  };

  const handleJoinCampaign = (item: any) => {
    addCampaignCharacter({
      variables: {
        campaignCharacter: { campaignId: item.id, userId: item.owner.id },
      },
    });
  };

  const pageHeaderActions = [
    {
      title: 'Add Campaign',
      href: '/campaigns/add/',
    },
  ];

  const [defaultColumnWidths] = useState<TableColumnWidthInfo[]>([
    { columnName: 'name', width: 'auto' },
    { columnName: 'owner', width: 150 },
    { columnName: 'current_players', width: 150 },
    { columnName: 'max_number_of_players', width: 150 },
    { columnName: 'actions', width: 300 },
  ]);

  const [columnWidths, setColumnWidths] = useState(defaultColumnWidths);

  const [sorting, setSorting] = useState<Sorting[]>([{ columnName: 'name', direction: 'asc' }]);

  const [sortingStateColumnExtensions] = useState([{ columnName: 'actions', sortingEnabled: false }]);

  const isOwner = (id: string) => {
    return myUserId === id;
  };

  const canJoin = (row) => {
    return myUserId !== row.owner.id && row.campaign_characters_aggregate.aggregate.count < row.max_number_of_players;
  };

  const columns = [
    { name: 'name', title: 'Campaign Name' },
    { name: 'owner', title: 'Game Master', getCellValue: (row) => (row.owner ? row.owner.username : '') },
    {
      name: 'current_players',
      title: 'Current Players',
      getCellValue: (row) =>
        row.campaign_characters_aggregate && row.campaign_characters_aggregate.aggregate
          ? row.campaign_characters_aggregate.aggregate.count
          : 0,
    },
    { name: 'max_number_of_players', title: 'Max Players' },
    {
      name: 'actions',
      title: 'Actions',
      getCellValue: (row) => {
        return (
          <>
            <Action variant="contained" color="primary" href={`/campaigns/${row.id}`}>
              View
            </Action>
            {isOwner(row.owner.id) && (
              <>
                <Action variant="contained" color="primary" href={`/campaigns/${row.id}/edit`}>
                  Edit
                </Action>
                <Action
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleDeleteCampaign(row);
                  }}
                >
                  Delete
                </Action>
              </>
            )}
            {canJoin(row) && (
              <Action
                variant="contained"
                color="primary"
                onClick={() => {
                  handleJoinCampaign(row);
                }}
              >
                Join
              </Action>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (queryLoading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <StyledCampaigns>
      <PageHeader actions={pageHeaderActions}>Campaigns</PageHeader>

      <Grid rows={data[resourceKey].map(({ __typename, ...item }) => item)} columns={columns}>
        <SortingState sorting={sorting} onSortingChange={setSorting} columnExtensions={sortingStateColumnExtensions} />
        <IntegratedSorting />
        <Table />
        <TableColumnResizing
          columnWidths={columnWidths}
          minColumnWidth={100}
          resizingMode="nextColumn"
          onColumnWidthsChange={setColumnWidths}
        />
        <TableHeaderRow showSortingControls />
      </Grid>
    </StyledCampaigns>
  );
};

export default withRouter(CampaignsList);
