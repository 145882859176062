import gql from 'graphql-tag';

export const AlertStatus = {
  fragments: {
    alert_status: {}
  }
};

AlertStatus.fragments = {
  alert_status: gql`
    fragment alertStatusFields on alert_status {
      id
      created_at
      updated_at
      key
      name
    }
  `
};