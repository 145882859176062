import React, { useState } from 'react';

import styled from 'styled-components';

import { useLazyQuery } from '@apollo/react-hooks';

import { useParams, withRouter } from 'react-router-dom';

import { CHARACTER_QUERY_BY_ID } from '@taboset/graphql';

import CharactersForm from '../characters-form/characters-form';

const StyledCharactersAdd = styled.div`

`;

function getCharacterData(data: any) { // eslint-disable-line
  return (data.character[0]);
}

export const CharactersAdd = () => {
  const { characterId } = useParams();

  const [loading, setLoading] = useState(false);
  const [character, setCharacter] = useState(null);

  const updateCharacter = (characterData) => {
    setCharacter(getCharacterData(characterData));
    setLoading(false);
  };

  const [
    fetchCharacter,
    { called, data, loading: queryLoading, error }, // eslint-disable-line
  ] = useLazyQuery(CHARACTER_QUERY_BY_ID, {
    variables: {
      id: characterId,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (d) => updateCharacter(d)
  });

  if (characterId && !character && !loading) {
    setLoading(true);
    fetchCharacter();
  }

  if (loading) return (<h1>Loading</h1>);

  return (
    <StyledCharactersAdd>
      <h1>Add a new Character</h1>
      <CharactersForm character={character} />
    </StyledCharactersAdd>
  );
};

export default withRouter(CharactersAdd);
