import React from 'react';

import styled from 'styled-components';

import { Link, withRouter } from 'react-router-dom';

import { PermUser } from '../../components/auth/requirements';

const HomeCont = styled.div``;

export const HomePage = () => {
  return (
    <HomeCont>
      <h1>Welcome to Taboset!</h1>
      <h2>Coming Soon</h2>
      <h4>Here are some pages:</h4>
      <ul>
        <li>
          <Link to="/">
            Home <strong></strong>
          </Link>
        </li>
        <PermUser>
          <li>
            <Link to="/campaigns">Go to Campaigns Page</Link>
          </li>
        </PermUser>
        <li>
          <Link to="/characters">Go to Characters Page</Link>
        </li>
        <li>
          <Link to="/news">Go to News Page</Link>
        </li>
      </ul>
    </HomeCont>
  );
};

export default withRouter(HomePage);
