import React from 'react';

import { useHistory } from 'react-router';

import { useForm } from 'react-hook-form';

import { useMutation } from '@apollo/react-hooks';

import { MUTATION_CHARACTER_ADD, MUTATION_CHARACTER_UPDATE } from '@taboset/graphql';

/*eslint-disable */
export interface Character {
  id: number,
  name: string,
  race_id: number
  user_id: string
}
/*eslint-enable */

export interface CharactersFormProps {
  character?: Character
}

export const CharactersForm = (props: CharactersFormProps) => { // eslint-disable-line
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm({
    defaultValues: props.character ? props.character : {}
  });

  const [addCharacter, { loading: addLoading, data: characterAddData }] = useMutation(MUTATION_CHARACTER_ADD, { // eslint-disable-line
    onCompleted({ insert_character: { affected_rows: inserted, returning: [character] } }) {
      console.log("We added a character!", inserted, character, character.id);
      history.push(`/characters/`);
    }
  });

  const [updateCharacter, { loading: updateLoading, data: characterUpdateData }] = useMutation(MUTATION_CHARACTER_UPDATE, { // eslint-disable-line
    onCompleted({ update_character: { affected_rows: updated } }) {
      console.log("We updated a character!", updated);
      history.push(`/characters/`);
    }
  });

  /*eslint-disable */
  const onSubmit = (addData: any) => { // eslint-disable-line
    if (props.character) {
      updateCharacter({
        variables: {
          id: props.character.id,
          name: addData.name
        }
      });
    } else {
      addCharacter({
        variables: {
          character: {
            name: addData.name,
            race_id: 1
          }
        }
      });
    }
  };
  /*eslint-enable */

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input name="name" ref={register({ required: true })} />
      {errors.name && 'Character name is required.'}

      <input type="submit" value="Save Character" />
    </form>
  )
}

export default CharactersForm;
