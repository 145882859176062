import React from 'react';

import { Link } from 'react-router-dom';

import { Admin } from 'styled-icons/remix-fill';

import { HeaderLinks } from '../links';

export const HeaderAdmin = () => {
  return (
    <HeaderLinks borderLeft>
      <Link to="/admin">
        <Admin />
      </Link>
    </HeaderLinks>
  );
};

export default HeaderAdmin;
