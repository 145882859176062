import React from 'react';

import styled from 'styled-components';

import Button from '@material-ui/core/Button';

const ActionCont = styled.div``;

interface PageHeaderActionProps {
  title: string;
  href: string;
}

export const PageHeaderAction = (props: PageHeaderActionProps) => {
  return (
    <ActionCont>
      <Button variant="contained" color="primary" href={props.href}>
        {props.title}
      </Button>
    </ActionCont>
  );
};

export default PageHeaderAction;
