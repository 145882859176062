export const LANGUAGES = ['en', 'zh', 'ru', 'pt', 'es', 'fr', 'de', 'ja', 'aa', 'tr'];

// Valid languages to use in production
export const LANGUAGES_LABEL = [
  {
    code: 'en',
    text: 'English',
  },
  {
    code: 'zh',
    text: '中文',
  },
  {
    code: 'ru',
    text: 'Русский',
  },
  {
    code: 'pt',
    text: 'Português',
  },
  {
    code: 'es',
    text: 'Español',
  },
  {
    code: 'fr',
    text: 'Français',
  },
  {
    code: 'de',
    text: 'Deutsch',
  },
  {
    code: 'ja',
    text: '日本語',
  },
  {
    code: 'tr',
    text: 'Türkçe',
  },
];
