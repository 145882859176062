import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';


import { withRouter } from 'react-router-dom';

import { NEWS_QUERY } from '@taboset/graphql';
import { useQuery } from '@apollo/react-hooks';

import moment from 'moment';

import NewsItem from '../../../../assets/news-placeholder.png';


const useStyles = makeStyles((theme) => ({
  root: {
    color: 'black',
    display: 'flex',
  },
  newsImage: { height: '100%' },
}))

const resourceKey = 'news';

export const NewsList = (props: any) => { // eslint-disable-line
  const { loading: queryLoading, error, data, refetch } = useQuery(NEWS_QUERY);
  const classes = useStyles();

  /*eslint-disable */
  useEffect(() => {
    refetch();
  }, [])
  /*eslint-enable */

  if (queryLoading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <Grid container className={classes.root} justify='center'>
      <Grid item xs={8}><h1>News List</h1></Grid>
      {data[resourceKey].map((item: any, index: any) => ( // eslint-disable-line
        <Grid item xs={8} key={item.id}>
          <Card className={classes.root}>
            <Grid container>
              <Grid item xs={2}>
                <CardMedia
                  className={classes.newsImage}
                  image={NewsItem}
                  title="News Item Image"
                />
              </Grid>
              <Grid item xs={10}>
                <CardHeader
                  title={item.title}
                  subheader={moment(item.updated_at).format("MMM Do")}
                  action={
                    <Button size="small" href={`/news/${item.id}`}>
                      View Details
                      </Button>
                  }
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default withRouter(NewsList);
