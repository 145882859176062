import React from 'react';

import styled from 'styled-components';

import { useQuery } from '@apollo/react-hooks';

import { useParams, withRouter } from 'react-router-dom';

import { CHARACTER_QUERY_BY_ID_ALL } from '@taboset/graphql';

import { Typography, Grid } from '@material-ui/core';

import moment from 'moment';

const StyledCharactersView = styled.div`
  color: black;
`;

const StyledHeader = styled(Typography)`
  font-weight: 700;
`;

const StyledDetailItem = styled(Typography)`
`;

export const CharactersView = () => {
  const { characterId } = useParams();

  const { loading: queryLoading, error, data } = useQuery(
    CHARACTER_QUERY_BY_ID_ALL,
    {
      variables: { id: Number(characterId) }
    }
  );

  if (queryLoading) return <p>Loading...</p>;
  if (error) return (
    <>
      <h1>There was an error fetching the character</h1>
      <p>
        {error.message}
      </p>
      <pre>Bad: {error.graphQLErrors.map(({ message }, i) => (
        <span key={i}>{message}</span>
      ))}
      </pre>
    </>
  )

  const character = data.character[0];

  return (
    <StyledCharactersView>
      <Typography variant='h4' gutterBottom>Viewing Details of: <b>{character.name}</b></Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <StyledHeader>Character Name</StyledHeader>
          <StyledDetailItem data-testid='character-name'>{character.name}</StyledDetailItem>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledHeader>Date Created</StyledHeader>
          <StyledDetailItem data-testid='created-date'>{moment(character.created_at).utc().format('MMMM Do YYYY, h:mm:ss a')}</StyledDetailItem>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledHeader>Last Updated</StyledHeader>
          <StyledDetailItem data-testid='updated-date'>{moment(character.updated_at).utc().format('MMMM Do YYYY, h:mm:ss a')}</StyledDetailItem>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledHeader>Race Name</StyledHeader>
          <StyledDetailItem data-testid='race-name'>{character.race.name}</StyledDetailItem>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledHeader>User Name</StyledHeader>
          <StyledDetailItem data-testid='username'>{character.user.username}</StyledDetailItem>
        </Grid>
      </Grid>
    </StyledCharactersView >
  );
};

export default withRouter(CharactersView);
