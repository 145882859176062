import React from 'react';

import styled from 'styled-components';

import { useQuery } from '@apollo/react-hooks';

import { useParams, withRouter } from 'react-router-dom';

import { CAMPAIGN_QUERY_BY_ID_ALL } from '@taboset/graphql';

import { Typography, Grid } from '@material-ui/core';

import moment from 'moment';

const StyledCampaignsView = styled.div`
  color: black;
`;

const StyledHeader = styled(Typography)`
  font-weight: 700;
`;

const StyledDetailItem = styled(Typography)`
`;

export const CampaignsView = () => {
  const { campaignId } = useParams();

  const { loading: queryLoading, error, data, refetch, networkStatus } = useQuery(
    CAMPAIGN_QUERY_BY_ID_ALL,
    {
      variables: { id: campaignId }
    }
  );

  if (queryLoading) return <p>Loading...</p>;
  if (error) return (
    <>
      <h1>There was an error fetching the campaign</h1>
      <p>
        {error.message}
      </p>
      <pre>Bad: {error.graphQLErrors.map(({ message }, i) => (
        <span key={i}>{message}</span>
      ))}
      </pre>
    </>
  )

  const campaign = data.campaign[0];

  return (
    <StyledCampaignsView>
      <Typography variant='h4' gutterBottom>Viewing Details of: <b>{campaign.name}</b></Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <StyledHeader>Campaign Name</StyledHeader>
          <StyledDetailItem data-testid='campaign-name'>{campaign.name}</StyledDetailItem>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledHeader>Date Created</StyledHeader>
          <StyledDetailItem data-testid='created-date'>{moment(campaign.created_at).utc().format('MMMM Do YYYY, h:mm:ss a')}</StyledDetailItem>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledHeader>Last Updated</StyledHeader>
          <StyledDetailItem data-testid='updated-date'>{moment(campaign.created_at).utc().format('MMMM Do YYYY, h:mm:ss a')}</StyledDetailItem>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledHeader>Max Number of Players</StyledHeader>
          <StyledDetailItem data-testid='max-players'>{campaign.max_number_of_players}</StyledDetailItem>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledHeader>Owned By</StyledHeader>
          <StyledDetailItem data-testid='owner-username'>{campaign.owner.username}</StyledDetailItem>
        </Grid>
      </Grid>
    </StyledCampaignsView >
  );
};

export default withRouter(CampaignsView);
