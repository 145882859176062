import React from 'react';

import { Switch, Route, withRouter } from 'react-router-dom';

import NewsList from './news-list/news-list';
import NewsView from './news-view/news-view';

export const News = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.url + '/'} component={NewsList} />
      <Route exact path={match.url + '/:newsId'} component={NewsView} />
    </Switch>
  );
};

export default withRouter(News);
