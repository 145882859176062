import React from 'react';

import { Switch, Route, withRouter } from 'react-router-dom';

import AccountHomePage from './account-home/account-home';
import SigninPage from './signin/signin';
import RegisterPage from './register/register';
import ForgotPasswordPage from './forgot-password/forgot-password';
import SelectUsernamePage from './select-username/select-username';

export const Account = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.url + '/signin'} component={SigninPage} />
      <Route exact path={match.url + '/forgot-password'} component={ForgotPasswordPage} />
      <Route exact path={match.url + '/register'} component={RegisterPage} />
      <Route exact path={match.url + '/select-username'} component={SelectUsernamePage} />
      <Route path={match.url + '/'} component={AccountHomePage} />
    </Switch>
  );
};

export default withRouter(Account);
